import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Content, { HTMLContent } from '../components/content'
import Carousel from 'nuka-carousel';
import Img from 'gatsby-image'; 
import SEO from '../components/seo';

export class HomePageTemplate extends React.Component {
  componentDidMount() {
    setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
    }, 0);
  }

  render() {
    const { title, images = [], content, contentComponent } = this.props;
    const PageContent = contentComponent || Content;
    return (
      <section className="section section--home">
        <div className="container">
          <Carousel
            autoplay
            swiping={false}
            dragging={false}
            withoutControls={true}
            className="sa-slider"
            wrapAround
            initialSlideHeight={400}
          >
            {images.map((i, n) => (
              <Img 
                fluid={i.childImageSharp.fluid} 
                key={`slide-${n}`} 
                critical={n === 0}
              />
            ))}
          </Carousel>
          <p className="mobile-only">DJ |  Music Collector | Deep House Enthusiast</p>
          <PageContent className="content" content={content} />
        </div>
      </section>
    )
  }
}

HomePageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  images: PropTypes.array,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const HomePage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <SEO keywords={['DJ', 'Samuel', 'Andres', 'Pittsburgh', 'House']} />
      <HomePageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        images={post.frontmatter.images}
        content={post.html}
      />
    </Layout>
  )
}

HomePage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default HomePage

export const homePageQuery = graphql`
  query HomePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        images{
          childImageSharp {
            fluid(maxWidth:1600, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`